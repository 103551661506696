import React from "react"
import Layout from "../components/Layout"

const copy = {
  en: {
    title: "About",
    canhk_heading: "CANHK",
    canhk_body:
      "The CANHK portal is an initiative of the Welcome Church project, which is owned by Our Common Calling. CANHK seeks to assist and welcome Hong Kong newcomers to Canada by providing helpful information and connecting them with resources and supportive communities as they settle into Canada.",
    occ_heading: "Understanding Our Common Calling",
    occ_body:
      "Our Common Calling (OCC) is a partnership of four Canadian national groups: The Evangelical Fellowship of Canada (EFC), Lausanne Canada, the Canadian Centre for Christian Charities (CCCC) and Christian Higher Education Canada (CHEC). The OCC partnership exists to encourage collaboration amongst God’s people as we together follow Jesus into the world.",
    welcome_church_heading: "The Welcome Church Project",
    welcome_church_body_1:
      "Canada is known globally for celebrating diversity, welcoming people from many different communities who enrich our national identity. The Canadian church has played an important role in that welcoming, reflecting God’s heart for all peoples. We believe that hospitality is an expression of God’s character, an opportunity to show mercy, to stand up for justice, and to be responsible stewards of the many blessings that we share as Canadians. As God’s people have an opportunity to practice radical hospitality, welcoming the stranger into our communities.",
    welcome_church_body_2:
      "The Welcome Church Project recognizes that Canadian churches have not always lived up to God’s standards of justice and equity, both in welcoming newcomers and in respecting the first peoples on this land. We seek to journey with newcomers to Canada, so that we may together build a community where all peoples can thrive.",
  },
  cn: {
    title: "關於",
    canhk_heading: "CANHK網站",
    canhk_body:
      "CANHK是屬於「歡迎教會計劃」(Welcome Church Project)的網站，由 「Our Common Calling」組織所擁有。CANHK希望幫助及歡迎香港人來到加拿大，提供實際的幫助，在大家定居加拿大時，協助您們儘快得到教會及社區的各種支持。",
    occ_heading: "認識Our Common Calling",
    occ_body:
      "Our Common Calling (OCC) 是四個加拿大國家團體的合作單位：包括「加拿大福音派團契 」(EFC)、「加拿大洛桑」、「加拿大基督教慈善中心」 (CCCC) 及「加拿大基督教高等教育」 (CHEC)。OCC的夥伴關係是為了跟隨耶穌基督進入世界，鼓勵上帝的子民之間的合作關係，從而祝福更多有需要的人。",
    welcome_church_heading: "歡迎教會計劃",
    welcome_church_body_1:
      "加拿大以慶祝多樣性而聞名於世，歡迎來自許多不同群體的人豐富我們對國家的身份認同。加拿大教會在這種接待中發揮了重要作用，反映了上帝對所有人的心意。我們相信熱情接待是表達了上帝樂於施恩的性情，同時也是一個捍衛公義、憐憫和負責任地管理我們作為加拿大人所分享的許多祝福的機會。在一個充滿不公義和不寬容的世界裡，上帝的子民有機會實行奮進的款待，歡迎新朋友進入我們的社區之中。",
    welcome_church_body_2:
      "「歡迎教會計劃」承認加拿大教會在歡迎新來加人士和尊重這片土地上的第一批人民方面，並未符合上帝的公義和公平的標準。我們尋求與加拿大的新移民一起踏上旅程，以讓我們可以共同建立一個所有人都擁有公義和平等的社區。",
  },
}

const About = ({ lang }) => {
  const translate = key => copy[lang][key]

  return (
    <Layout lang={lang}>
      <section className="header">
        <h1 className="header__title">{translate("title")}</h1>
        <p className="header__description"></p>
      </section>
      <section className="about">
        <h2>{translate("canhk_heading")}</h2>
        <p>{translate("canhk_body")}</p>
        <h2>{translate("occ_heading")}</h2>
        <p>{translate("occ_body")}</p>
        <h2>{translate("welcome_church_heading")}</h2>
        <p>{translate("welcome_church_body_1")}</p>
        <p>{translate("welcome_church_body_2")}</p>
      </section>
    </Layout>
  )
}

export default About
